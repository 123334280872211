<template>
  <div class="flex flex-col flex-1 overflow-hidden bg-white border border-gray-300 rounded">
    
    <POrderGrid
      ref="ordergrid"
      transactionType="HE"
      method="scheduleorders"
      nested="schedules"
      :cols="cols" 
      :expandAll="expandAll"
      @click="onClick"
    ></POrderGrid>

    <portal to="actions">
      <div class="flex items-center ml-4 space-x-4">
        
        <PToggle 
          :stacked="true" 
          :label="$tk('Common.General.Detailed')" 
          v-model="expandAll" 
        />
        
        <PButton 
          color="primary"
          @click="orderDialogVisible = true"
          :disabled="customer.statusId === 2"           
        >
          <span v-html="$tk('ScheduledDispatch.NewScheduledPickup')"></span>
        </PButton>

      </div>
    </portal>


    <OrderDialog 
      v-if="orderDialogVisible" 
      transactionType="HE"
      method="scheduleorder"
      :orderId="orderId"
      @close="onClose" 
    />

  </div>
</template>

<script>
import { mapGetters } from "vuex"
import OrderDialog from "../../orders/dialogs/OrderDialog.vue"

export default {

  components: {
    OrderDialog
  },

  data () {
    return {      
      cols: [        
        { text: this.$tk('Common.Order.StartDate'), value: "dateBegins", sortable: true, component: "p-display-date", width: 110, xalign: "center" },
        { text: this.$tk('Common.Order.EndDate'), value: "dateEnds", sortable: true, component: "p-display-date", width: 110, xalign: "center" },
        { text: this.$tk('Common.Days.Mo')[0], value: "createMonday", component: "p-display-boolean", width: 50, align: "center" },
        { text: this.$tk('Common.Days.Tu')[0], value: "createTuesday", component: "p-display-boolean", width: 50, align: "center" },
        { text: this.$tk('Common.Days.We')[0], value: "createWednesday", component: "p-display-boolean", width: 50, align: "center" },
        { text: this.$tk('Common.Days.Th')[0], value: "createThursday", component: "p-display-boolean", width: 50, align: "center" },
        { text: this.$tk('Common.Days.Fr')[0], value: "createFriday", component: "p-display-boolean", width: 50, align: "center" },
        { text: this.$tk('Common.Order.Interval'), value: "createNumWeeks", component: "p-display-text", width: 80, align: "center" },
        { 
          nested: [            
            { text: this.$tk('Common.Order.RTI'), component: "p-display-product", aggregate: "p-display-product-images", nested: "schedules", align: "left", width: 220 },
            { text: this.$tk('Common.Order.Volume'), value: "quantityOrdered", component: "p-display-number", aggregate: "p-display-sum", nested: "schedules", width: 80, align: "right" },
          ] 
        },
        { text: "%", value: "scheduleFill", component: "p-display-text", align: "left" },
        { text: this.$tk("Common.Order.PickupAddress"), value: "addressFrom", sortable: true, component: "p-display-address", config: { showPrimary: false } },
        { text: this.$tk('Common.Order.MyReference'), value: "reference", component: "p-display-text", align: "left" },

      ],     
      orderId: null,
      expandAll: false,
      filters: {},
      orderDialogVisible: false
    }
  },

  computed: {
    ...mapGetters(["customer"])
  },

  methods: {    
    
    onClick ({ order }) {
        this.orderId = order.id
        this.orderDialogVisible = true
      },

    onClose () {      
      this.orderId = null
      this.orderDialogVisible = false
      this.$refs.ordergrid.getOrders()
    }      
  }

}
</script>
